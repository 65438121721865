<!--  -->
<template>
  <div class="contens">
    <div class="img">
      <div class="titlecc">分类设备统计</div>
    </div>
    <div class="mains">
      <div class="bgs">
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newtower.png" alt=""  class="imgss">
          </div>
          <div class="box1">  {{ towerCount }}</div>
          <div class="box2">设备总数(台)</div>
          </div>
          
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newtower.png" alt="" class="imgss">
          </div>
          <div class="box3">  {{ towerOnlineCount }}</div>
          <div class="box2">在线数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newlift.png" alt="" class="imgss">
          </div>
          <div class="box1">  {{ elevatorCount }}</div>
          <div class="box2">设备总数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newlift.png" alt="" class="imgss">
          </div>
          <div class="box3">  {{ elevatorOnlineCount }}</div>
          <div class="box2">在线数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newmachine.png" alt="" class="imgss">
          </div>
          <div class="box1">  {{ bridgeCount }}</div>
          <div class="box2">设备总数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newmachine.png" alt="" class="imgss">
          </div>
          <div class="box3">  {{ bridgeOnlineCount }}</div>
          <div class="box2">在线数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newbasket.png" alt="" class="imgss">
          </div>
          <div class="box1">  {{ cradleCount }}</div>
          <div class="box2">设备总数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newbasket.png" alt="" class="imgss">
          </div>
          <div class="box3">  {{ cradleOnlineCount }}</div>
          <div class="box2">在线数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newdoor.png" alt="" class="imgss" >
          </div>
          <div class="box1">  {{ gantrycraneCount }}</div>
          <div class="box2">设备总数(台)</div>
          </div>
        </div>
        <div class="cc">
          <div class="xxx">
            <div class="box">
            <img src="../../assets/images/screen/newdoor.png" alt="" class="imgss">
          </div>
          <div class="box3">  {{ gantrycraneOnlineCount }}</div>
          <div class="box2">在线数(台)</div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import screen from '../../api/modules/screen'
export default {
  components: {},
  props:['projId'],
  data() {
    return {
      projectCount:'',
      deviceCount:'',
      towerCount:'',
      towerOnlineCount:'',
      elevatorCount:'',
      elevatorOnlineCount:'',
      bridgeCount:'',
      bridgeOnlineCount:'',
      cradleCount:'',
      cradleOnlineCount:'',
      gantrycraneCount:'',
      gantrycraneOnlineCount:'',
      deviceOnlineCount:'',
      deviceOfflineCount:''
    };
  },
  mounted() {
    
  },
  watch:{
    projId(val){
      this.getInit(val)
    }
  },
  methods: {
    //
    getInit(val){
      screen.getBigScreenDeviceCount({projId:val}).then(res=>{
      
        this.projectCount=res.data.projectCount
        this.deviceCount =res.data.deviceCount	
        this.towerCount = res.data.towerCount
        this.towerOnlineCount=res.data.towerOnlineCount
        this.elevatorCount=res.data.elevatorCount
        this.elevatorOnlineCount=res.data.elevatorOnlineCount
        this.bridgeCount =res.data.bridgeCount
        this.bridgeOnlineCount=res.data.bridgeOnlineCount
        this.cradleCount=res.data.cradleCount
        this.cradleOnlineCount=res.data.cradleOnlineCount
        this.gantrycraneCount=res.data.gantrycraneCount
        this.gantrycraneOnlineCount=res.data.gantrycraneOnlineCount
        this.deviceOnlineCount=res.data.deviceOnlineCount
        this.deviceOfflineCount=res.data.deviceOfflineCount
      })

    },
  },
};
</script>


<style lang="less" scoped>
.contens {
  height: 100%;
}
.img {
  background-image: url("../../assets/images/screen/classification.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 10%;
  width: 100%;
  position: relative;
  // margin-bottom: 30px;
}
.mains {
  height: 90%;
  overflow: auto;
}
.bgs{
  display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}
.cc{
  width: 50%; /* 每行显示两个div */
    box-sizing: border-box;
    height: 20%;
}
.xxx{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.box1{
  color: #00C9F0;
  font-size: 20px;
  margin-right: 5%;
  margin-left: 5%;
}
.box3{
  color: #31F692;
  font-size: 20px;
  margin-right: 5%;
  margin-left: 5%;
}
.imgss{
  height: 90%;
}
.box{
  height: 100%;
}
.titlecc{
 margin-left: 10%;
 padding-top: 3.5% ;
}
</style>
