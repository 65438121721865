<template>
    <div style="height: 100%; width: 100%;">
      <div class="classificationBg">
        <div class="classification">设备统计</div>
      </div>
      <!-- <dv-scroll-board :config="config" style="width:100%;height:200px" /> -->
      <div>
        <el-table :data="tableData"  style="width: 100%;color: #FFFFFF;" :height="400" class="tables" >
          <el-table-column prop="createTime" label="时间" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="projName" label="项目" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceType" label="类型" > 
            <template slot-scope="scope">
                      <div v-if="scope.row.deviceType==0"  style="color:#00C9F0">{{ '塔吊' }}</div>
                      <div v-if="scope.row.deviceType==1"  style="color:#00C9F0">{{ '升降机' }}</div>
                      <div v-if="scope.row.deviceType==2"  style="color:#00C9F0">{{ '龙门吊' }}</div>
                      <div v-if="scope.row.deviceType==3"  style="color:#00C9F0">{{ '架桥机' }}</div>
                      <div v-if="scope.row.deviceType==4"  style="color:#00C9F0">{{ '爬模' }}</div>
                      <div v-if="scope.row.deviceType==5"  style="color:#00C9F0">{{ '挂篮' }}</div>
                    </template>
          </el-table-column>
          <el-table-column prop="deviceNo" label="现场编号" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="deviceImei" label="设备编号" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="deviceOnline" label="在线状态" show-overflow-tooltip>
            <template slot-scope="scope">
                      <div v-if="scope.row.deviceOnline==0"  style="color:#00C9F0">{{ '在线' }}</div>
                      <div v-if="scope.row.deviceOnline==1"  style="color:#00C9F0">{{ '离线' }}</div>
                      
                    </template>  
          </el-table-column>
        </el-table>
      </div>
    </div>
  </template>
  
  <script>
  import screen from '../../api/modules/screen'
  export default {
    props:['projId'],
    components: {},
    data() {
      return {
        tableData: [
         
        ],
      };
    },
    watch:{
      projId(val) {
       
        this.getAlarmTodayLists(val)
        
      },
    },
    mounted() {
      // this.getAlarmTodayLists()
  //     setInterval(() => {
  //   // （也可以使用`this.$ref`获得表格元素让母后`.classList.remove("el-table--enable-row-hover")`）
  //       document.getElementById("playList").classList.remove("el-table--enable-row-hover")
  //   })
  
    },
    methods: {
      getAlarmTodayLists(val){
        let params={
          projId:val
        }
        screen.getListByPage(params).then(res=>{
            this.tableData = res.data
       })
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
 .classificationBg {
  background-image: url("../../assets/images/screen/classification.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 8%;
  width: 100%;
  position: relative;
}
.classification {
  margin-left: 8%;
 padding-top: 1.5% ;
  color: #fff;
}
  /*最外层透明*/
  /deep/ .el-table,
  /deep/ .el-table__expanded-cell {
    background-color: transparent;
  }
  /* 表格内背景颜色 */
  /deep/ .el-table th,
  /deep/ .el-table tr,
  /deep/ .el-table td {
    background-color: transparent;
  }
  /deep/ .el-table th:hover,
  /deep/ .el-table tr:hover,
  /deep/ .el-table td:hover {
    background-color: transparent;
  }
  /deep/ .el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
  }
   /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #1c1c1b4a !important;
  }
  /deep/.el-table .el-table__body td {
    border-bottom: none;
  }
  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/.el-table thead {
      color: #fff;
      font-weight: 500;
  }
  </style>
  