
<template>
 <div class="mapss">
   
 
   <div style="height: 100%; width:100%">
        <mapDel ></mapDel>
    </div>
 </div>
</template>

<script>
import mapDel from '../../components/screen/map.vue'
export default {
 components:{
    mapDel
 },
 data () {
  return {
    options:[],
    value:''
  }
 },
 mounted(){

 },
 methods:{

 },
}

</script>

<style lang='less' scoped>
.mapss{
    height: 100%;
  width: 100%;
  

}

/deep/.el-input__inner {
    -webkit-appearance: none;
    background-color:transparent !important;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #409eff;
    box-sizing: border-box;
    color: #409eff;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
</style>
