<template>
  <span>{{ times }}</span>
</template>

<script>
export default {
  name: "Clocks",
  data() {
    return {
      times: "", //格式化之后的当前时间
      currentTime: new Date(), //当前时间
      timer: null
    }
  },
  //   初始化，获取数据集
  created() {
    // 获取当前时间
    this.getData();
  },
  // 钩子函数，操作dom元素
  mounted() {
  },
  // 组件销毁
  destroyed() {
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  // 监听器
  watch: {},
  // 事件方法
  methods: {
    // 当前时间
    getData() {
      //获取当前时间
      let year = new Date().getFullYear(); //获取当前时间的年份
      let month = new Date().getMonth() + 1; //获取当前时间的月份
      let day = new Date().getDate(); //获取当前时间的天数
      let hours = new Date().getHours(); //获取当前时间的小时
      let minutes = new Date().getMinutes(); //获取当前时间的分数
      let seconds = new Date().getSeconds(); //获取当前时间的秒数
      // let wk = new Date().getDay(); // 获取当前时间的周历
      // let weeks = [
      //   "星期日",
      //   "星期一",
      //   "星期二",
      //   "星期三",
      //   "星期四",
      //   "星期五",
      //   "星期六",
      // ];
      //当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      // let week = weeks[wk];
      //拼接格式化当前时间
      this.times =
          // "当前时间 ：" +
          year +
          "年" +
          month +
          "月" +
          day +
          "日 " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
      // +
      // " " +
      // week;
      // 启动定时器，每隔1秒钟动态渲染当前日期时间
      this.setTimeFu()
    },
    setTimeFu() {
      this.timer = setTimeout(() => {
        this.getTimesInterval()
        this.setTimeFu()
      }, 1000)

    },
    getTimesInterval() {
      //获取当前时间
      let year = new Date().getFullYear(); //获取当前时间的年份
      let month = new Date().getMonth() + 1; //获取当前时间的月份
      let day = new Date().getDate(); //获取当前时间的天数
      let hours = new Date().getHours(); //获取当前时间的小时
      let minutes = new Date().getMinutes(); //获取当前时间的分数
      let seconds = new Date().getSeconds(); //获取当前时间的秒数
      // let wk = new Date().getDay(); // 获取当前时间的周历
      // let weeks = [
      //   "星期日",
      //   "星期一",
      //   "星期二",
      //   "星期三",
      //   "星期四",
      //   "星期五",
      //   "星期六",
      // ];
      //当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      // let week = weeks[wk];
      //拼接格式化当前时间
      this.times =
          // "当前时间 ：" +
          year +
          "年" +
          month +
          "月" +
          day +
          "日 " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
      // +
      // " " +
      // week;
    },
  }
}
</script>

<style scoped>

</style>