
<template>
 <div style="height: 100%; width: 100%">
    <div class="classificationBg">
      <div class="classification">告警与违章统计</div>
    </div>
    <div class="main-box">
     
     <div class="chart-box" ref="monthWarn"></div>
   </div>
 </div>
</template>

<script>
import * as echarts from "echarts";
import { getMothWarn } from "../../components/screen/chartOption";
import screen from '../../api/modules/screen'
export default {
 components:{

 },
 props:['projId'],
 data () {
  return {
    chartMonthWarn: null,
  }
 },
 watch:{
    projId(val){
      this.initCarTrendChar(val)
    }
  },
 mounted(){
    // this.initCarTrendChar();
 },
 methods:{
    initCarTrendChar(val) {
      screen.getAlarmCount({projId:val}).then(res=>{
      
    //   var seriesdata1 = [
    //   { name: "告警", value: res.data.alarmCount,data: res.data.alarm },
    //   { name: "违规", value: res.data.llegalCount,data: res.data.llegal},
    
    // ];
    console.log('res',res);

    var scale = 1;
      var echartData = [
        {
          value: res.data.llegalCount,
          name: "违规",
        
        },
        {
          value: res.data.alarmCount,
          name: "告警",
        
        },
      ];
  

      var rich = {
        yellow: {
          color: "#81E9BF",
          fontSize: 20 * scale,
          padding: [5, 7],
          align: "left",
        },
        total: {
          color: "#7efec3",
          fontSize: 21 * scale,
          align: "center",
        },
        white: {
          color: "#fff",
          align: "center",
          fontSize: 12 * scale,
          padding: [10, 0],
        },
        blue: {
          color: "#49dff0",
          fontSize: 12 * scale,
          align: "center",
        },
        hr: {
          borderColor: "#81E9BF",
          width: "100%",
          borderWidth: 1,
          height: 0,
        },
      };

      if (!this.chartMonthWarn) {
        this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
      }

      const option = getMothWarn(echartData, rich, scale);

      this.chartMonthWarn.setOption(option);
   
    })
      
    },
 },
}

</script>

<style lang='less' scoped>
.classificationBg {
  background-image: url("../../assets/images/screen/classification.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 20%;
  width: 100%;
  position: relative;
}
.classification {
   margin-left: 10%;
 padding-top: 3.5% ;
//   position: absolute;
//   left: 7%;
//   top: 50%;
//   transform: translate(-7%, -50%);
  color: #fff;
}
.main-box {
  height: 80%;
  overflow: auto;
  .chart-box {
    height: 100%;
  
    // width: 600px;
  }
}
</style>
