
<template>
 <div style="height: 100%;">
    <div class="classificationBg">
      <div class="classification">分类设备统计</div>
    </div>
    <div class="main-box">
     
    <div class="chart-box" ref="monthWarn"></div>
  </div>
 </div>
</template>

<script>
import screen from '../../api/modules/screen'
import * as echarts from "echarts";
import { getMothWarn } from "../../components/screen/chartOption";
export default {
 components:{

 },
 props:['projId'],
 data () {
  return {
    chartMonthWarn: null,
    deviceOnlineCount:'',
      deviceOfflineCount:''
  }
 },
 watch:{
    projId(val){
      this.getInit(val)
    }
  },
 methods:{
  getInit(val){
      screen.getBigScreenDeviceCount({projId:val}).then(res=>{
      
        this.deviceOnlineCount=res.data.deviceOnlineCount
        this.deviceOfflineCount=res.data.deviceOfflineCount
        this.initCarTrendChar(this.deviceOnlineCount,this.deviceOfflineCount);
      })

    },
    initCarTrendChar(deviceOnlineCount,deviceOfflineCount) {
      var scale = 1;
      var echartData = [
        {
          value:deviceOfflineCount?deviceOfflineCount:0,
          name: "离线数量",
       
        },
        {
          value:deviceOnlineCount?deviceOnlineCount:0,
          name: "在线数量",
        
        },
      ];
  
      
      var rich = {
        yellow: {
          color: "#81E9BF",
          fontSize: 20 * scale,
          padding: [5, 7],
          align: "center",
        },
        total: {
          color: "#7efec3",
          fontSize: 20 * scale,
          align: "center",
        },
        white: {
          color: "#fff",
          align: "center",
          fontSize: 14 * scale,
          padding: [10, 0],
        },
        blue: {
          color: "#49dff0",
          fontSize: 16 * scale,
          align: "center",
        },
        hr: {
          borderColor: "#81E9BF",
          width: "100%",
          borderWidth: 1,
          height: 0,
        },
      };

      if (!this.chartMonthWarn) {
        this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
      }

      const option = getMothWarn(echartData, rich, scale);

      this.chartMonthWarn.setOption(option);
    },
 },
}

</script>

<style lang='less' scoped>
.classificationBg {
  background-image: url("../../assets/images/screen/classification.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 20%;
  width: 100%;
  position: relative;
  
}
.classification {
  // position: absolute;
  // left: 10%;
  // top: 50%;
  // transform: translate(-10%, -50%);
  margin-left: 10%;
 padding-top: 3.5% ;
  color: #fff;
}
.main-box {
  height: 80%;
  overflow: auto;
  .chart-box {
    height: 100%;
    // width: 600px;
  }
}
</style>
