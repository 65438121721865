<!--  -->
<template>
 <div style="height: 100%; width: 100%">
    <div class="classificationBg">
      <div class="classification">分类告警排行</div>
    </div>
    <div class="main-box">
    <div class="chart-box" ref="monthWarn"></div>
  </div>
 </div>
</template>

<script>
import * as echarts from "echarts";
import { getWarnData } from "../../components/screen/chartOption";
import screen from "../../api/modules/screen";
export default {
  components: {},
  props:['projId'],
  data() {
    return {
      chartMonthWarn: null,
      m: "",
    };
  },
  mounted() {
    // this.initCarTrendChar();
  },
  watch:{
    projId(val){
      this.initCarTrendChar(val)
    }
  },
  methods: {
    initCarTrendChar(val) {
        let diff = []
      let salvProName = [];
      let salvProValue = [];
      screen.getAlarmClassCount({projId:val}).then((res) => {
        res.data.map((item) => {
            diff.push({
                name:item.alarmKind,
                value:item.toatalCount,
                unit:''
            })
        //   salvProName.push(item.alarmKind);
        //   salvProValue.push(item.toatalCount);
        });

        var salvProMax = []; //背景按最大值
        for (let i = 0; i < salvProValue.length; i++) {
          salvProMax.push(salvProValue[0]);
        }

        if (!this.chartMonthWarn) {
          this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
        }

        const option = getWarnData(diff);

        this.chartMonthWarn.setOption(option);
        // this.getDefaultSelected(this.chartMonthWarn);
      });
    },
  },
};
</script>

<style lang='less' scoped>
.classificationBg {
  background-image: url("../../assets/images/screen/classification.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 18%;
  width: 100%;
  position: relative;
}
.classification {
  margin-left: 10%;
 padding-top: 3.5% ;
  color: #fff;
}
.main-box {
  height: 82%;
  overflow: auto;
  .chart-box {
    height: 100%;
    // width: 600px;
    // overflow: scroll;
   
  }
}
</style>
