<template>
  <div class="container">
    <div class="header">
      <ScreenHead></ScreenHead>
    </div>
    <div class="main">
      <div class="main-left">
        <div class="left-top">
          <devicesTotal :projId="projId"></devicesTotal>
        </div>
        <div class="left-middle">
          <equipmentClassificationStatistics
            :projId="projId"
          ></equipmentClassificationStatistics>
        </div>
        <div class="left-bottom">
          <equipmentOnlineStatistics
            :projId="projId"
          ></equipmentOnlineStatistics>
        </div>
      </div>
      <div class="main-center">
        <div class="center-top">
          <div class="selectProject">
            <el-select v-model="value" filterable placeholder="请选择"    @change="searchChange" class="selects">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <deviceTable :projId="projId"></deviceTable>
        </div>
        <div class="center-bottom"><toDay :projId="projId"></toDay></div>
      </div>
      <div class="main-right">
        <div class="right-top"> <warnViolation :projId="projId"></warnViolation></div>
        <div class="right-middle">  <classificationAlarm :projId="projId"></classificationAlarm></div>
        <div class="right-bottom"><facialStatistics :projId="projId"></facialStatistics></div>
      </div>
    </div>
  </div>
</template>
<script>
import ScreenHead from "../../../components/screen/screenHead.vue";
import devicesTotal from "../../../components/screen/devicesTotal.vue";
import equipmentClassificationStatistics from "../../../components/screen/equipmentClassificationStatistics.vue";
import equipmentOnlineStatistics from "../../../components/screen/equipmentOnlineStatistics.vue";
import projectMaps from "../../../components/screen/projectMaps.vue";
import toDay from "../../../components/screen/toDay.vue";
import warnViolation from "../../../components/screen/warnViolation.vue";
import classificationAlarm from "../../../components/screen/classificationAlarm.vue";
import facialStatistics from "../../../components/screen/facialStatistics.vue";
import screen from "../../../api/modules/screen";
import deviceTable from "../../../components/screen/deviceTable.vue";
export default {
  components: {
    ScreenHead,
    devicesTotal,
    equipmentClassificationStatistics,
    equipmentOnlineStatistics,
    projectMaps,
    toDay,
    warnViolation,
    classificationAlarm,
    facialStatistics,
    deviceTable
  },
  data() {
    return {
      options: [],
      value: "",
      projId: "",
    };
  },
  mounted() {
    // createConnect()
    this.getprojuct();
  },
  methods: {
    getprojuct() {
      screen.getProjectScreenList().then((res) => {
        // this.projId = null;
        res.data.map((item) => {
          this.options.push({
            label: item.projName,
            value: item.projId,
          });
        });
        this.projId = res.data[0].projId
      });
     
    },
    searchChange(e) {
      console.log(e, "e");
      this.projId = e;
      // this.getAlarmTodayLists()
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background-image: url("../../../assets/images/screen/newbg2.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: #fff;
}

.header {
  height: 12%;
}

.main {
  display: flex;
  height: 87%;
  padding: 0 16px;
  overflow: hidden;
}

.main-left,
.main-right {
  width: 25%;
}

.main-center {
  width: 50%;
}

.left-top,
.left-middle,
.left-bottom,
.right-top,
.right-middle,
.right-bottom {
  /* height: 30%; */
  /* border: 1px solid red; */
}
.left-top {
  height: 8%;
  /* margin-bottom: 10px; */
  margin-bottom: 4%;

}
.left-middle {
  height: 58%;

  /* overflow: auto; */

}
.left-bottom {
  height: 32%;
 
}
.right-top {
  height: 32%;
  /* overflow: auto; */
   /* margin-bottom: 10px; */
}
.right-middle {
  height: 36%;
}
.right-bottom {
  height: 32%;
  /* overflow: auto; */
}
.center-top,
.center-bottom {
  /* height: 50%; */
  /* border: 1px solid red; */
}
.center-top {
  height: 68%;
  // overflow: auto;
}
.center-bottom {
  height: 32%;
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: transparent !important;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #409eff;
  box-sizing: border-box;
  color: #409eff; 
  display: inline-block;
  height: 35px; 
  line-height: 35px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
::v-deep .el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    background-color: transparent !important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    box-sizing: border-box;
    margin: 5px 0;
}
.selectProject{
 padding-top: 20px;
  
}
::v-deep html ::-webkit-scrollbar {
    width: 2px !important;
    height: 8px; 
}
</style>
