import api from "../httpServer";
import config from "../config";
import store from "@/store/index";

const screen = {
  getBigScreenDeviceCount(data) {
    return api.get(
      `${config.headerUrl}device/screen/getBigScreenDeviceCount`,
      data
    );
  },
  //大屏项目下拉框列表
  getProjectScreenList(data) {
    return api.get(
      `${config.headerUrl}device/screen/getProjectScreenList`,
      data
    );
  },
  //大屏当日告警
  getAlarmTodayList(data) {
    return api.get(`${config.headerUrl}device/screen/getAlarmTodayList`, data);
  },
  //大屏告警与违章统计
  getAlarmCount(data) {
    return api.get(`${config.headerUrl}device/screen/getAlarmCount`, data);
  },
  //大屏分类告警排行
  getAlarmClassCount(data) {
    return api.get(`${config.headerUrl}device/screen/getAlarmClassCount`, data);
  },
  //大屏人脸识别统计
  getFaceRecordCount(data) {
    return api.get(`${config.headerUrl}device/screen/getFaceRecordCount`, data);
  },
  //实时数据获取
  realDataList(data) {
    return api.get(
      `${config.headerUrl}monitor/device/count/realDataList`,
      data
    );
  },
  //工作循环
  towerWork(data) {
    return api.get(`${config.headerUrl}monitor/device/count/towerWork`, data);
  },
  //查询当日报警
  getProjectAlarmList(data) {
    return api.get(
      `${config.headerUrl}monitor/alarm/getProjectAlarmList`,
      data
    );
  },
  //查询驾驶司机列表
  getPageList(data) {
    return api.get(`${config.headerUrl}monitor/tower/getPageList`, data);
  },
  //查询视频列表
  getListCamera(data) {
    return api.get(`${config.headerUrl}camera/listCamera`, data);
  },
  //查询视频列表
  deviceParam(data) {
    return api.get(`${config.headerUrl}v1/debug/deviceParam`, data);
  },
  //查询设备是否在线
  selectOnline(data) {
    return api.get(`${config.headerUrl}device/terminal/selectOnline/${data}`);
  },
  //黑匣子时间详情查询
  selectDeviceTime(data) {
    return api.get(
      `${config.headerUrl}device/terminal/selectDeviceTime/${data}`
    );
  },
  //黑匣子告警详情查询
  selectDeviceAlarm(data) {
    return api.get(
      `${config.headerUrl}device/terminal/selectDeviceAlarm`,
      data
    );
  },
  //工况设置
  getTerm(data) {
    return api.get(`${config.headerUrl}mqtt/getTerm`, data);
  },
  //实时数据历史
  selectEsData(data) {
    return api.get(`${config.headerUrl}mqtt/selectEsData`, data);
  },
  //告警明细列表
  getAlarmListByPage(data) {
    return api.get(
      `${config.headerUrl}/data/device/alarm/getAlarmListByPage`,
      data
    );
  },
  //人脸识别记录
  getFaceRecordListByPage(data) {
    return api.get(
      `${config.headerUrl}/data/face/record/getFaceRecordListByPage`,
      data
    );
  },
  //项目设备
  getListByPage(data) {
    return api.get(`${config.headerUrl}/device/terminal/getList`, data);
  },
  //博瓦
  getbowaUrl(data) {
    return api.get(`${config.headerUrl}camera/getTokenAndUrlAndData`, data);
  },
  //大屏树查询
  realDataLists(data) {
    return api.post(`${config.headerUrl}treeapi/realDataList`, data);
  },
  //获取扬尘实时数据
  getRealData(data) {
    return api.get(`${config.headerUrl}monitor/device/construction/getRealData`, data);
  },
  //扬尘监控大屏获取设备信息
  getDeviecInfo(data) {
    return api.get(`${config.headerUrl}monitor/device/construction/getDeviecInfo`, data);
  },
};

export default screen;
