
<template>
    <div style="height: 100%; width: 100%">
       <div class="classificationBg">
         <div class="classification">人脸识别统计</div>
       </div>
       <div class="main-box">
        
        <div class="chart-box" ref="monthWarn"></div>
      </div>
    </div>
   </template>
   
   <script>
   import * as echarts from "echarts";
   import { getMothWarn } from "../../components/screen/chartOption";
   import screen from '../../api/modules/screen'
   export default {
    components:{
   
    },
    props:['projId'],
    data () {
     return {
       chartMonthWarn: null,
     }
    },
    watch:{
    projId(val){
      this.initCarTrendChar(val)
    }
  },
    mounted(){
      //  this.initCarTrendChar();
    },
    methods:{
       initCarTrendChar(val) {
        console.log(val,'11');
        screen.getFaceRecordCount({projId:val}).then((res) => {
          var scale = 1;
         var echartData = [
           {
             value: res.data.faceCount? res.data.faceCount:0,
             name: "今日识别",
           
           },
           {
             value: res.data.unFaceCount?res.data.unFaceCount:0,
             name: "未识别",
           
           },
         ];
     
         
         var rich = {
           yellow: {
             color: "#81E9BF",
             fontSize: 20 * scale,
             padding: [5, 7],
             align: "center",
           },
           total: {
             color: "#7efec3",
             fontSize: 20 * scale,
             align: "center",
           },
           white: {
             color: "#fff",
             align: "center",
             fontSize: 14 * scale,
             padding: [10, 0],
           },
           blue: {
             color: "#49dff0",
             fontSize: 16 * scale,
             align: "center",
           },
           hr: {
             borderColor: "#81E9BF",
             width: "100%",
             borderWidth: 1,
             height: 0,
           },
         };
   
         if (!this.chartMonthWarn) {
           this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
         }
   
         const option = getMothWarn(echartData, rich, scale);
   
         this.chartMonthWarn.setOption(option);
    });



        
       },
    },
   }
   
   </script>
   
   <style lang='less' scoped>
   .classificationBg {
     background-image: url("../../assets/images/screen/classification.png");
     background-repeat: no-repeat;
     background-size: 100% 100%;
     height: 20%;
   //   width: 100%;
   //   position: relative;
   text-align: left;
  //  line-height: 39px;
   
   }
   .classification {
    margin-left: 10%;
 padding-top: 3.5% ;
   //   position: absolute;
   //   left: 7%;
   //   top: 50%;
   //   transform: translate(-7%, -50%);
     color: #fff;
   }
   .main-box {
     height: 80%;
     overflow: auto;
     .chart-box {
       height: 100%;
      
       // width: 600px;
     }
   }
   </style>
   