<template>
  <div id="app">
    <div id="echart_china" ref="echart_china"></div>
  </div>
</template>

<script>
import chinamap from "@/assets/echarts/china.json";
import * as echarts from "echarts";
import screen from "../../api/modules/screen";
export default {
  data() {
    return {
      myChart: null,
      datas: [],
    };
  },
  mounted() {
    // 1. 创建一个 ECharts 实例，返回 echartsInstance，不能在单个容器上初始化多个 ECharts 实例
    this.myChart = echarts.init(this.$refs.echart_china);
    this.fetchAndDisplayData();
  },
  methods: {
    fetchAndDisplayData() {
      screen.getProjectScreenList().then((res) => {
        this.datas = res.data.map(item => ({
          name: item.projName,
          value: [item.projLong, item.projLot],
        }));
        this.setupMap();
      });
    },

    setupMap() {
      echarts.registerMap("china", chinamap);
      this.setOptions();
    },
    setOptions() {
      const option = {
        // ... 省略其他 option 设置 ...
        tooltip: {
              show: true, // 显示 tooltip
              trigger: "item", // 触发类型，默认数据触发，可选为：'item' | 'axis'
              formatter: function (params) {
                // 格式化 tooltip 内容
             

                return (
                  params.name +
                  "<br/>"
                  //  +
                  // params.value[0]
                  //  +
                  // ", " +
                  // params.value[1]
                );
              },
            },
        geo: {
          type: "map",
          map: "china",
          zoom: 1.3, // 设置地图缩放比例
          label: {
            // label 设置文本标签的显示格式，去掉不影响显示地图
            normal: {
              color: "#fff",
              show: true, //显示省份名称
            },
          },
         
          roam: true,
          itemStyle: {
            // normal: {
            //   areaColor: "#4f9cc8",
            //   borderColor: "#1180c7",
            // },
            normal: {
                areaColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#4f9cc8" }, // 渐变色起始颜色
                    { offset: 1, color: "#1180c7" }, // 渐变色结束颜色
                ]),
                borderColor: "#1180c7",
            },
            emphasis: {
              areaColor: "#4499d0", //鼠标移入的背景颜色
            },
          },
        },
        series: [
          {
            name: "在地图中显示散点图",
            type: "scatter",
            itemStyle: {
              color: "#9af7b5", // 圆点的颜色
            },
            symbolSize: function(val) {
             
              
                return val[0] / 8;
            },
           
            coordinateSystem: "geo", //设置坐标系为 geo
             data:this.datas,
       
           
          },
        ],
      };
      this.myChart.setOption(option);
      this.myChart.on('click',params =>{
          this.$router.push("/view/monitor/deviceTotal");
        })
    },
   

  },
};
</script>

<style scoped>
#app{
  height: 100%;
}
#echart_china {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  /* background-color: #f1f3f4; */
}
</style>
