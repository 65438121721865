<template>
  <div class="screen-head">
    <!-- <div class="head-middle-title">起重机械智能监控平台</div> -->
    <div class="name-time-box">
      <Clocks class="times"></Clocks>
        <!-- <img src="../../assets/images/screen/signal.png" class="signal">
      <div class="name">在线</div> -->
    </div>
    <div class="head-right">
      <!-- <div class="right-item border">
        <i class="project-icon"></i>

      </div> -->
      <!-- <div class="right-item border">
        <img src="../../assets/images/screen/head-project-message.png">
      </div> -->
      <div class="right-item" @click="goSysCenter">
        <img src="../../assets/images/screen/web.png"  >
        <span class="title">管理系统</span>
      </div>
    </div>
  </div>
</template> 

<script>
import Clocks from '../common/Clocks.vue'
export default {
  name: "index",
  components:{Clocks},
  data() {
    return {};
  },
  methods: {
    goSysCenter() {
      this.$router.push("/view/monitor/deviceTotal");
    },
  },
};
</script>

<style scoped lang="less">
.screen-head {
    
  // text-align: center;
  // // font-family: Microsoft YaHei;
  // color: #ffffff;
  // margin-top: 30px;
  background-image: url("../../assets/images/screen/newhead.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    text-align: center;
    position: relative;
    height: 100%;
    // height: 130px;
}
.head-middle-title {
  font-size: 24px;
  letter-spacing: 6px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: HuXiaoBoNanShenTi-2;
  color: #ffffff;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: 20px;
}
.name-time-box{
      font-weight: bold;
      color:#3CA3FB;
      font-size: 12px;
      display: flex;
      align-items: center;
      position: absolute;
      top: 40px;
      left: 20px;
      .name{
        margin-left: 5px;
        color: #3CA3FB;
      }
      .signal{
        width: 20px;
        height: 20px;
      }
    }
    .head-right{
      position: absolute;
      right: 20px;
      top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      .project-icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../assets/images/screen/head-project.png") no-repeat;
        background-position: center;
        background-size: contain;
        position: relative;
        top: 5px;
      }
      .right-item{
        padding: 0 10px;
        color: #D8EBFF;
        font-size: 13px;
        position: relative;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
        &.border{
          &:after{
            content: '';
            position: absolute;
            right: 0;
            top: 10px;
            width: 1px;
            height: 20px;
            background: linear-gradient(0deg,rgba(225,225,225,0.3) 0%, #0075eb 50%, rgba(225,225,225,0.3) 100%);
          }
        }
        .title{
          position: relative;
          margin-left: 5px;
          color:#3CA3FB;
        }
        .project-title{
          font-size: 14px;
          letter-spacing: 3px;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: HuXiaoBoNanShenTi-2;
          color: #ffffff;
          background: linear-gradient(0deg, #0075eb 0%, #d8ebff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        img{
          max-width: 20px;
          max-height: 20px;
          position: relative;
          top: 5px;
        }
      }
    }
</style>
