<template>
  <div class="totalBox">
    <div class="deviceNum">
      <div class="num">{{ deviceCount }}</div>
      <div class="title">设备总数</div>
    </div>
    <div class="projectNum">
      <div class="projecnum">{{ projectCount }}</div>
      <div class="projectitle">项目数</div>
    </div>
  </div>
</template>

<script>
import screen from '../../api/modules/screen'
export default {
  components: {},
  props:['projId'],
  data() {
    return {
      projectCount:'',
      deviceCount:'',
      towerCount:'',
      towerOnlineCount:'',
      elevatorCount:'',
      elevatorOnlineCount:'',
      bridgeCount:'',
      bridgeOnlineCount:'',
      cradleCount:'',
      cradleOnlineCount:'',
      gantrycraneCount:'',
      gantrycraneOnlineCount:'',
      deviceOnlineCount:'',
      deviceOfflineCount:'',
      projIds:''
    };
  },

  watch:{
    projId(val){
      this.getInit(val)
    
    }
  },
  mounted(){
    this.getInit()
  },
  methods: {

    getInit(projId){
      screen.getBigScreenDeviceCount({projId:projId}).then(res=>{
      
        this.projectCount=res.data.projectCount
        this.deviceCount =res.data.deviceCount	
        this.towerCount = res.data.towerCount
        this.towerOnlineCount=res.data.towerOnlineCount
        this.elevatorCount=res.data.elevatorCount
        this.elevatorOnlineCount=res.data.elevatorOnlineCount
        this.bridgeCount =res.data.bridgeCount
        this.bridgeOnlineCount=res.data.bridgeOnlineCount
        this.cradleCount=res.data.cradleCount
        this.cradleOnlineCount=res.data.cradleOnlineCount
        this.gantrycraneCount=res.data.gantrycraneCount
        this.gantrycraneOnlineCount=res.data.gantrycraneOnlineCount
        this.deviceOnlineCount=res.data.deviceOnlineCount
        this.deviceOfflineCount=res.data.deviceOfflineCount
      })

    },
  },
};
</script>

<style lang="less" scoped>
.totalBox {
  display: flex;
  align-items: center;
  height: 100%;
}
.deviceNum {
  background-image: url("../../assets/images/screen/deviceNum.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 100%;
  //   width: 238px;
  width: 50%;
  //   background-position: center;
  position: relative;
  color: #fff;
}

.projectNum {
  background-image: url("../../assets/images/screen/projectNum.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //   width: 238px;
  display: flex;
  align-items: center;
  width: 50%;
  // justify-content: center;
  background-position: center;
  position: relative;
  color: #fff;


}

.num {
  font-size: 0.5rem;
  margin-right: 2%;
  margin-left:  30%;;
  // position: absolute;
  // left: 45%;
  // top: 50%;
  // transform: translate(-45%, -50%);
}
.title {
  font-size: 100%;

  // position: absolute;
  // right: 10%;
  // top: 50%;
  // transform: translate(-10%, -50%);
  color: #00c9f0;
}
.projecnum {
  font-size: 0.5rem;
  margin-right: 2%;
  margin-left: 30%;
  // position: absolute;
  // left: 45%;
  // top: 50%;
  // transform: translate(-45%, -50%);
}
.projectitle {
  font-size: 100%;

  // position: absolute;
  // right: 10%;
  // top: 50%;
  // transform: translate(-10%, -50%);
  color: #00c9f0;
}
</style>
